import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
}
const LandingContainer: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <div className={`max-w-[1280px] px-4 mx-auto ${className}`}>{children}</div>
  )
}

export default LandingContainer
