import Link from 'next/link'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

import LandingLanguageDropdown from '@/components/ui/LandingLanguageDropdown'
import { CONTACT_NUMBER } from '@/res/consts'
import { IconBlogs, IconLogoLanding, IconPhones } from '@/res/icons'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingContainer from '../LandingContainer'

const LandingNavbar = ({ hamburgerMenu, setHamburgerMenu }: any) => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)

  const { data } = useSession()

  return (
    <div
      className={`laptop:py-6 py-4 border-b text-white border-[#444444] transition-all sticky top-0 z-[99] bg-black`}
    >
      <LandingContainer>
        <div className={`flex items-center justify-between`}>
          <div className="flex gap-2">
            <div
              className={`h-16 px-4 flex items-center rounded-2xl bg-white/10 ${
                hamburgerMenu ? 'blur-lg' : null
              }`}
            >
              <Link href="/">
                <a className="hidden laptop:hidden tablet:block ">
                  <img src={IconBlogs.src} className="h-8 " alt="" />
                </a>
              </Link>
              <Link href="/">
                <a className="block laptop:block tablet:hidden ">
                  <img src={IconLogoLanding.src} className="h-12 " alt="" />
                </a>
              </Link>
            </div>
            <div className="justify-between px-3 rounded-2xl tablet:bg-white/10 lg:max-w-7xl tablet:items-center tablet:flex">
              <div className="justify-between hidden pb-3 mt-8 justify-self-center tablet:pb-0 tablet:mt-0 tablet:flex">
                <ul className="flex flex-col gap-4 font-medium uppercase tablet:flex-row desktop:space-x-2 tablet:space-x-2 ">
                  <li className="cursor-pointer">
                    <Link href="#services">{t('navbar.services')}</Link>
                  </li>
                  <li className="cursor-pointer">
                    <Link href="#about">{t('navbar.about_us')}</Link>
                  </li>
                  <li className="cursor-pointer">
                    <Link href="#tools">{t('navbar.tools')}</Link>
                  </li>
                  <li className="cursor-pointer">
                    <Link href="#projects">{t('navbar.our_projects')}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="justify-between px-3 mx-auto font-medium rounded-2xl tablet:bg-white/10 lg:max-w-7xl tablet:items-center tablet:flex">
              <a
                href={`tel:+998 ${CONTACT_NUMBER}`}
                className="justify-between hidden gap-2 pb-3 mt-8 justify-self-center tablet:pb-0 tablet:mt-0 tablet:flex"
              >
                <IconPhones />
                <span className="hidden laptop:block">
                  +998 {CONTACT_NUMBER}
                </span>
              </a>
            </div>
          </div>

          <div>
            <div className="z-10 tablet:hidden ">
              <button
                className="text-white rounded-md outline-none table:focus:border-gray-400 table:focus:border"
                onClick={() => setHamburgerMenu(true)}
              >
                {hamburgerMenu ? null : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div
              className={`fixed my-4 mx-4 px-4 py-6 top-0 w-[300px] -right-4 transition-all z-20  rounded-2xl   bg-white/10
                  ${hamburgerMenu ? 'translate-x-0' : 'translate-x-full'}
            `}
            >
              <div className="flex items-center justify-between pb-4 border-b">
                <div>
                  <Link href={data?.user ? '/profile' : 'login'}>
                    <button className="block transition ease-in-out rounded ">
                      {data?.user ? t('navbar.account') : t('navbar.login')}
                    </button>
                  </Link>
                </div>
                <div className="block float-right tablet:hidden">
                  <button
                    className="p-2 text-center text-white border-2 rounded-md outline-none focus:border-gray-400"
                    onClick={() => setHamburgerMenu(false)}
                  >
                    {hamburgerMenu ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 text-center"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>

              <ul className="flex flex-col items-start mt-8 mb-4 font-bold text-white border-b tablet:flex-row desktop:space-x-8 tablet:space-x-6">
                <li className="mb-4 border-gray-400 cursor-pointer">
                  <Link href="#services">
                    <a onClick={() => setHamburgerMenu(false)} href="">
                      {t('navbar.services')}
                    </a>
                  </Link>
                </li>
                <li className="mb-4 border-gray-400 cursor-pointer">
                  <Link href="#about">
                    <a onClick={() => setHamburgerMenu(false)} href="">
                      {t('navbar.why_us')}
                    </a>
                  </Link>
                </li>
                <li className="mb-4 border-gray-400 cursor-pointer">
                  <Link href="#dashboard">
                    <a onClick={() => setHamburgerMenu(false)} href="">
                      {t('navbar.solution')}
                    </a>
                  </Link>
                </li>

                <li className="mb-4 border-gray-400 cursor-pointer">
                  <Link href="#request">
                    <a onClick={() => setHamburgerMenu(false)} href="">
                      {t('navbar.contact_with_us')}
                    </a>
                  </Link>
                </li>
              </ul>
              <div className="flex flex-col gap-3">
                <div>
                  <LandingLanguageDropdown />
                </div>
                <div className="flex gap-2 justify-self-center">
                  <IconPhones />
                  <a href="tel:+998 71 201 01 01">+998 71 201 01 01</a>
                </div>
              </div>
            </div>
            <div className="hidden gap-2 font-medium tablet:flex">
              <Link
                href={data?.user ? '/profile' : 'login'}
                className="rounded-2xl bg-white/10"
              >
                <button className="px-4 py-5 ml-2 transition ease-in-out rounded-2xl bg-white/10">
                  {data?.user ? t('navbar.account') : t('navbar.login')}
                </button>
              </Link>
              <LandingLanguageDropdown />
            </div>
          </div>
        </div>
      </LandingContainer>
    </div>
  )
}

export default LandingNavbar
