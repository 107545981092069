import { API_ROUTE_GET_NEWS } from '@/api/apiRoutes'
import api from '@/lib/api'
import { INews } from '@/types/News'

interface Response {
  results: INews[]
}

export const getLastNews = async (lang: string) => {
  try {
    const { data } = await api.get<Response>(API_ROUTE_GET_NEWS, {
      params: {
        page_size: 12,
      },
      headers: {
        'Accept-Language': lang,
      },
    })

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
