import { ITypo } from '@/types/Typo'

const Caption: React.FC<ITypo> = ({
  children,
  className = '',
  color = 'textPrimary',
  weight = 'font-normal',
}) => {
  return (
    <p
      className={`${className} ${weight} hr:text-tiny tablet:text-[14px] text-[13px] leading-5 ${color}`}
    >
      {children}
    </p>
  )
}

export default Caption
