import { ITypo } from '@/types/Typo'

const H2: React.FC<ITypo> = ({
  color = 'text-dark',
  className = '',
  weight = 'font-normal',
  uppercase = true,
  children,
}) => {
  return (
    <h2
      className={`${color} ${weight} ${className} text-2xl desktop:text-[32px] desktop:leading-10 hr:text-6xl tablet:text-[28px] tablet:leading-9 w-full whitespace-pre-line ${
        uppercase ? 'uppercase' : 'normal-case'
      }`}
    >
      {children}
    </h2>
  )
}

export default H2
