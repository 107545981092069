import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { ROUTE_NEWS } from '@/res/routes'
import { INews } from '@/types/News'

import Caption from '../ui/Typo/Caption'

interface IProps {
  news: INews
}

const NewsCard: React.FC<IProps> = ({ news }) => {
  const timeOptions: any = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  const { lang } = useTranslation()
  return (
    <Link href={`${ROUTE_NEWS}/${news.slug}`} className="flex gap-4">
      <a>
        <div className="flex  justify-between sm:pl-6 small:pt-0 pt-6 sm:flex-row flex-col-reverse">
          <div className="flex flex-col  justify-between h-full  desktop:w-[446px] w-[240px] sm:pl-0 pl-5  ">
            <div>
              <div className="small:my-4 desktop:text-2xl font-medium desktop:w-[446px]  sm:w-[240px] w-[300px] tablet:text-base  text-sm text-[#0065E5]">
                {news.title}
              </div>
              <div className="small:my-4 my-2 sm:pt-0 pt-4 prose desktop:text-lg font-normal desktop:leading-10 text-sm desktop:w-full sm:w-[240px] w-[300px] ">
                <span dangerouslySetInnerHTML={{ __html: news.content }} />
              </div>
            </div>
            <div className="flex items-center">
              <Caption color="text-gray3">
                {new Date(news.created_at).toLocaleDateString(
                  lang || 'ru',
                  timeOptions
                )}
              </Caption>
            </div>
          </div>
          <div className=" desktop:pl-16 flex justify-center sm:flex-auto flex-none pb-4 tablet:px-4">
            <img
              src={news.banner}
              className="rounded-lg  desktop:w-[600px] desktop:h-[450px]  tablet:w-[300px]  h-[250px]"
              alt="adflow news"
            />
          </div>
        </div>
      </a>
    </Link>
  )
}

export default NewsCard
