import { Menu, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Fragment } from 'react'

import { IconPlanet } from '@/res/icons'
import { Langs } from '@/types/Common'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const LandingLanguageDropdown: React.FC = () => {
  const { lang } = useTranslation()
  const router = useRouter()

  const languages = [
    { code: 'ru', label: 'Ру' },
    { code: 'uz', label: "O'z" },
    { code: 'en', label: 'En' },
  ]

  const currentLanguage = languages.find((language) => language.code === lang)

  const onLangChange = (lang: Langs) => {
    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      router.asPath,
      {
        locale: lang,
        scroll: false,
      }
    )
  }

  return (
    <Menu
      as="div"
      className="relative flex items-center text-center rounded-2xl tablet:bg-white/10 tablet:px-2"
    >
      <div className="flex gap-2">
        <IconPlanet />
        <Menu.Button className="inline-flex justify-center font-medium text-white">
          {currentLanguage?.label}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute origin-top-right rounded-md shadow-lg tablet:right-0 tablet:left-0 left-16 tablet:mt-48 tablet:w-full bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {languages.map((language) => (
              <Menu.Item key={language.code}>
                {({ active }) => (
                  <button
                    onClick={() => onLangChange(language.code as Langs)}
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-black rounded-md'
                        : 'text-white',
                      'block px-4 py-2 w-full'
                    )}
                  >
                    {language.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default LandingLanguageDropdown
