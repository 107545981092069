import useTranslation from 'next-translate/useTranslation'
import { useEffect, useRef, useState } from 'react'
import React from 'react'
import Slider from 'react-slick'

import { getLastNews } from '@/api/other/getLastNews'
import H2 from '@/components/ui/Typo/H2'
import {
  IconNextArrow,
  IconNextArrowTab,
  IconPreviewArrow,
  IconPreviewArrowTab,
} from '@/res/icons'
import { ImageNews, ImageNewsSmall, ImageNewsTab } from '@/res/images'
import { NAMESPACE_COMMON } from '@/res/namespaces'
import { INews } from '@/types/News'

import LandingContainer from '../landing/LandingContainer'
import NewsCard from './NewsCard'

interface Props {
  isLatest?: boolean
}
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
const NewsList: React.FC<Props> = ({ isLatest }) => {
  const { t, lang } = useTranslation(NAMESPACE_COMMON)
  const [news, setNews] = useState<INews[]>([])
  const sliderRef = useRef<Slider>(null)
  const sliderRefTab = useRef<Slider>(null)
  const sliderRefSmall = useRef<Slider>(null)

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getLastNews(lang)
        setNews(res.results)
      } catch (err) {
        return
      }
    }

    getData()
  }, [lang])

  return (
    <div className="bg-[#F6F7F8] tablet:px-0 sm:px-16 px-0  sm:pt-[100px] pt-[50px] pb-[100px]">
      <LandingContainer>
        <div
          style={{
            backgroundImage: `url(${ImageNews.src})`,
          }}
          className="bg-no-repeat  bg-cover w-full  h-[644px] hidden desktop:block"
        >
          <div className=" h-[644px] flex flex-col justify-between">
            <div className="pl-6 pt-6">
              <H2 color="text-dark" weight="font-semibold" uppercase={false}>
                {isLatest ? t('last_news') : t('news')}
              </H2>{' '}
            </div>
            <div className=" ">
              <Slider ref={sliderRef} {...settings}>
                {news.map((item) => {
                  return <NewsCard news={item} key={item.title} />
                })}
              </Slider>
            </div>
            <div className="flex  hover:cursor-pointer gap-x-[25px]">
              <button className="bg-white p-6 rounded-2xl">
                <IconPreviewArrow
                  onClick={() => sliderRef.current?.slickPrev()}
                />
              </button>
              <button className="bg-white p-6 rounded-2xl">
                <IconNextArrow onClick={() => sliderRef.current?.slickNext()} />
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${ImageNewsTab.src})`,
          }}
          className="bg-no-repeat  bg-cover w-full   desktop:hidden sm:block hidden"
        >
          <div className=" flex flex-col justify-between">
            <div className="pl-6 pt-6">
              <H2 color="text-dark" weight="font-semibold" uppercase={false}>
                {isLatest ? t('last_news') : t('news')}
              </H2>{' '}
            </div>
            <div className=" ">
              <Slider ref={sliderRefTab} {...settings}>
                {news.map((item) => {
                  return <NewsCard news={item} key={item.title} />
                })}
              </Slider>
            </div>
            <div className="flex hover:cursor-pointer pb-[12px] pl-[12px] gap-x-[32px]">
              <button>
                <IconPreviewArrowTab
                  onClick={() => sliderRefTab.current?.slickPrev()}
                />
              </button>
              <button>
                <IconNextArrowTab
                  onClick={() => sliderRefTab.current?.slickNext()}
                />
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${ImageNewsSmall.src})`,
          }}
          className="bg-no-repeat  bg-cover  sm:hidden w-full h-[582px]"
        >
          <div className="h-[372px] flex flex-col justify-between">
            <div className="flex justify-between ">
              <div className="pt-4 pl-5  ">
                <H2 color="text-dark" weight="font-semibold" uppercase={true}>
                  {isLatest ? t('last_news') : t('news')}
                </H2>
              </div>
              <div className="flex hover:cursor-pointer  pb-1 gap-x-2">
                <button className="bg-white p-3 rounded-2xl">
                  <IconPreviewArrowTab
                    onClick={() => sliderRefSmall.current?.slickPrev()}
                  />
                </button>
                <button className="bg-white p-3 rounded-2xl ">
                  <IconNextArrowTab
                    onClick={() => sliderRefSmall.current?.slickNext()}
                  />
                </button>
              </div>
            </div>
            <div className=" ">
              <Slider ref={sliderRefSmall} {...settings}>
                {news.map((item) => {
                  return <NewsCard news={item} key={item.title} />
                })}
              </Slider>
            </div>
          </div>
        </div>
      </LandingContainer>
    </div>
  )
}

export default NewsList
